import { faStar } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import Slider from 'react-slick'

import {
  ITestimonialElement,
  ITestimonialWrapper,
} from '../../models/landingpage'
import { PictureTag } from '../helper/picture-tag'

interface TestimonialWrapperProps {
  data: ITestimonialWrapper
}

interface TestimonialProps {
  data: ITestimonialElement
}

const TestimonialBox = ({ data }: TestimonialProps) => {
  return (
    <div
      className={`h-full flex flex-col  mx-8 flex-grow justify-content items-center content-center p-2 hover:shadow-lg`}
    >
      <PictureTag
        imageUrl={data?.Image?.fullpath ?? '/sonstiges/failover.png'}
        sizesVW={`100`}
        className={` rounded-full border-xl w-64 border-black`}
        altText={data.Name}
      />
      <div
        className={`flex flex-col justify-center items-center bg-1 -mt-32 py-4 w-full`}
      >
        <p
          className={`relative sm:mx-auto text-xl  italic font-bold max-w-full pt-32  font-swDisp dark:text-white h-12 sm:h-auto`}
        >
          <FontAwesomeIcon className="w-6 text-xl text-black" icon={faStar} />{' '}
          <FontAwesomeIcon className="w-6 text-xl text-black" icon={faStar} />{' '}
          <FontAwesomeIcon className="w-6 text-xl text-black" icon={faStar} />{' '}
          <FontAwesomeIcon className="w-6 text-xl text-black" icon={faStar} />{' '}
          <FontAwesomeIcon className="w-6 text-xl text-black" icon={faStar} />
        </p>
        <p
          className={`relative sm:mx-auto text-center p-4 text-lg max-w-full h-56 sm:h-32 justify-center  items-center flex font-swDisp dark:text-white`}
        >
          {data.Testimonial}
        </p>
        <div className={`border-t-black w-3/4 border-t-xl `}></div>
        <p className={`uppercase text-2xl p-4 font-swDisp font-bold italic`}>
          {data.Name}
        </p>
      </div>
    </div>
  )
}

export const TestimonialWrapper = ({ data }: TestimonialWrapperProps) => {
  const itemcount = 3

  const settings = {
    dots: true,
    speed: 500,
    slidesToShow:
      data.Testimonials?.length < itemcount
        ? data.Testimonials?.length
        : itemcount,
    slidesToScroll:
      data?.Testimonials?.length >= 3
        ? itemcount < 3
          ? itemcount
          : 3
        : data?.Testimonials?.length < itemcount
        ? data?.Testimonials?.length
        : itemcount,
    infinite: false,
    nextArrow: <></>,
    prevArrow: <></>,
    initialSlide: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },

      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    // nextArrow: <NextArrow />,
    // prevArrow: <PrevArrow />,
  }
  return (
    <div className={`w-full bg-${data.Farbe} max-w-full`}>
      <div className={`z-0 container mx-auto py-16`}>
        <h3
          className={`pt-4 pb-8
           text-2xl sm:text-4xl
         italic font-bold font-swDisp text-center`}
        >
          {data.Headline}
        </h3>
        <p
          className={`pb-8
           text-xl sm:text-2xl
         italic font-bold font-swDisp text-center`}
        >
          {data.SubHeadline}
        </p>
        <div className="z-0 w-full">
          {data && (
            <Slider {...settings}>
              {data.Testimonials?.map((child, index) => (
                <TestimonialBox data={child} key={index} />
              ))}
            </Slider>
          )}
        </div>
      </div>
    </div>
  )
}

export default TestimonialWrapper
